export const START_TIMER = 'START_TIMER';
export const START_LINKED_TIMER = 'START_LINKED_TIMER';
export const END_TIMER = 'END_TIMER';
export const DELETE_TIMER = 'DELETE_TIMER';
export const LINK_WITH_TASK = 'LINK_WITH_TASK';
export const RENAME_TIMER = 'RENAME_TIMER';
export const UPDATE_HEARTBEAT = 'UPDATE_HEARTBEAT';

export const SET_TASKS = 'SET_TASKS';
export const ADD_TASK = 'ADD_TASK';


export const UPDATE_RANKING = 'UPDATE_RANKING';
export const SET_CRITERIA = 'SET_CRITERIA';
export const CLEAN_RANKING = 'CLEAN_RANKING';
export const PIN_TASK = 'PIN_TASK';
export const UNPIN_TASK = 'UNPIN_TASK';