import {
  colors,
  Divider,
  Drawer as MaterialDrawer,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import MUIAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MUIAccordionSummary from '@material-ui/core/AccordionSummary';
import { AccessTime, ChevronLeft, ChevronRight, ExpandMore, History } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import TimerIcon from '@material-ui/icons/Timer';
import { withStyles } from '@material-ui/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { startLinkedTimer } from '../../redux/actions';
import { DRAWER_WIDTH, mapActiveTasksForDrawer } from '../../utils/utils';
import AddTimer from './AddTimer';
import Timer from './Timer';

const AccordionSummary = withStyles({
  root: {
    '&$expanded': {
      margin: 0,
      minHeight: 0,
    },
  },
  expanded: {
    minHeight: 0,
  },
  content: {
    '&$expanded': {
      margin: 0,
    },
  },
})(MUIAccordionSummary);

const Accordion = withStyles({
  root: {
    '&$expanded': {
      margin: 0,
      minHeight: 0,
    },
  },
  expanded: {
    minHeight: 0,
    margin: 0,
  },
})(MUIAccordion);

const useStyles = makeStyles(theme => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    zIndex: 1000,
  },
  closeButton: {
    margin: 8,
  },
  unshadowedAccordion: {
    boxShadow: 'none',
  },
  withoutActionAccordionSummary: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
    width: '100%',
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
  },
  nameAndIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    overflow: 'hidden',
  },
  story: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  task: {
    marginLeft: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    width: '100%',
    marginBottom: '0.5rem',
  },
}));

const renderOptionDefault = option => (
  <ListItem button key={option}>
    <ListItemIcon>
      <History />
    </ListItemIcon>
    <ListItemText primary={option} />
  </ListItem>
);

const DividerWithText = ({ text }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: 16,
    }}
  >
    <Divider style={{ flex: 'auto' }} />
    <Typography
      style={{
        marginLeft: 10,
        marginRight: 10,
        fontSize: '0.8rem',
      }}
    >
      {text}
    </Typography>
    <Divider style={{ flex: 'auto' }} />
  </div>
);

const Task = ({ task, project, iteration, story, manualLoad, onAddTimer }) => {
  const [hovered, setHovered] = useState(false);
  const classes = useStyles();
  const handleClick = () =>
    manualLoad(
      {
        resource: {
          project,
          iteration,
          story,
          task,
        },
      },
      true
    );

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        height: 42,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div
        className={classes.nameAndIconContainer}
        onClick={handleClick}
        style={{
          width: '100%',
          cursor: 'pointer',
        }}
      >
        <Tooltip title={`Load effort for ${task.name}`}>
          <Typography
            noWrap
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              color: colors.grey[800],
              cursor: 'pointer',
            }}
          >
            {task.name}
          </Typography>
        </Tooltip>
      </div>
      <Tooltip title={`Create a timer for ${task.name}`}>
        <Fade in={hovered}>
          <IconButton onClick={() => onAddTimer(task)} style={{ padding: 0 }}>
            <TimerIcon style={{ cursor: 'pointer', color: colors.grey[600] }} />
          </IconButton>
        </Fade>
      </Tooltip>
    </div>
  );
};

const Drawer = ({ handleToggle, isOpen, options, renderOption = renderOptionDefault, anchor, header }) => {
  const classes = useStyles();
  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 960);
  const rightSide = anchor === 'right';

  useEffect(() => {
    const resize = () => setSmallScreen(window.innerWidth <= 960);

    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <MaterialDrawer
      className={classes.drawer}
      variant={smallScreen ? 'temporary' : 'persistent'}
      anchor={anchor}
      open={isOpen}
      onClose={handleToggle}
      classes={{ paper: classes.drawerPaper }}
      ModalProps={{ keepMounted: true }}
      style={{ display: !isOpen && 'none' }}
    >
      <div className={classes.drawerHeader}>
        <IconButton
          className={classes.closeButton}
          style={{ alignSelf: rightSide ? 'flex-start' : 'flex-end' }}
          onClick={handleToggle}
        >
          {rightSide ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
        <Divider />
        {header}
        <div style={{ flex: 1 }}>
          <List>{options.map(option => renderOption(option))}</List>
        </div>
      </div>
    </MaterialDrawer>
  );
};

const IterationsDrawer = ({ handleToggle, isOpen, manualLoad, toggleChronometers }) => {
  const activeTasks = useSelector(state => state.tasks.tasks);
  const classes = useStyles();

  const projects = useMemo(() => {
    console.log('Mapping tasks');
    return mapActiveTasksForDrawer(activeTasks);
  }, [activeTasks]);

  const timers = useSelector(state => state.timers.timers);
  const dispatch = useDispatch();

  const onAddTimer = useCallback(
    task => {
      console.log('Adding timer for task: ', task);
      if (timers.some(t => !t.end)) {
        return Swal.fire({
          title: 'You have an active timer',
          text: 'Please finish the current timer before adding a new one',
        });
      }

      dispatch(
        startLinkedTimer({
          task: activeTasks.find(t => t.id === task.id),
          name: task.name,
        })
      );

      toggleChronometers();
    },
    [activeTasks, dispatch, timers, toggleChronometers]
  );

  return (
    <Drawer
      anchor="left"
      isOpen={isOpen}
      handleToggle={handleToggle}
      options={projects}
      renderOption={project =>
        project.iterations.map(iteration => (
          <Accordion className={classes.unshadowedAccordion}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div className={classes.withoutActionAccordionSummary}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography style={{ fontSize: '0.8rem', color: colors.grey[700] }}>{project.name}</Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                  >
                    <Typography style={{ fontSize: '1rem', color: colors.grey[800] }}>{iteration.name}</Typography>
                    <Tooltip title="This iteration has expired">
                      <AccessTime
                        style={{
                          color: '#ff0000',
                          width: 16,
                          height: 16,
                          display: iteration.expired ? 'block' : 'none',
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {iteration.stories.map(story => (
                <Accordion className={classes.unshadowedAccordion}>
                  <AccordionSummary>
                    <div className={classes.story} style={{ width: '100%' }}>
                      <div className={classes.nameAndIconContainer}>
                        <Tooltip title={story.name}>
                          <Typography style={{ fontSize: '1rem', color: colors.grey[800] }}>{story.name}</Typography>
                        </Tooltip>
                      </div>
                      <Tooltip title={`Create a task in ${story.name}`}>
                        <AddIcon
                          style={{ cursor: 'pointer', color: colors.grey[600] }}
                          onClick={e => {
                            e.stopPropagation();
                            manualLoad({ resource: { project, iteration, story } }, true);
                          }}
                        />
                      </Tooltip>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    {story.tasks.map(task => (
                      <div className={classes.task}>
                        <Task
                          task={task}
                          project={project}
                          iteration={iteration}
                          story={story}
                          manualLoad={manualLoad}
                          onAddTimer={onAddTimer}
                        />
                        <Divider className={classes.divider} />
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}

              <Accordion className={classes.unshadowedAccordion}>
                <AccordionSummary>
                  <div className={classes.story} style={{ width: '100%' }}>
                    <div className={classes.nameAndIconContainer}>
                      <Tooltip title={'Tasks without story'}>
                        <Typography style={{ fontSize: '1rem', color: colors.grey[800] }}>
                          Tasks without story
                        </Typography>
                      </Tooltip>
                    </div>
                    <Tooltip title={`Create a task without story`}>
                      <AddIcon
                        style={{ cursor: 'pointer', color: colors.grey[600] }}
                        onClick={() => manualLoad({ resource: { project, iteration } }, true)}
                      />
                    </Tooltip>
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  {iteration.tasks.length > 0 ? (
                    iteration.tasks.map(task => (
                      <div className={classes.task}>
                        <Task
                          task={task}
                          project={project}
                          iteration={iteration}
                          manualLoad={manualLoad}
                          onAddTimer={onAddTimer}
                        />
                        <Divider className={classes.divider} />
                      </div>
                    ))
                  ) : (
                    <div className={classes.task}>
                      <Typography style={{ fontSize: '1rem', color: colors.grey[800] }}>
                        There are no tasks without story
                      </Typography>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
        ))
      }
    />
  );
};

const RunningTimer = ({ timer, handleOpen, addEvent }) => (
  <div
    style={{
      alignItems: 'center',
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 30,
    }}
  >
    <Timer
      info={timer}
      handleAction={handleOpen}
      addEvent={addEvent}
      actionTooltip={'Link with task'}
      onStop={() => {}}
    />
  </div>
);

const ChronometersDrawer = ({ handleToggle, isOpen, handleOpen, addEvent, handleStopIfNotLinked }) => {
  const timers = useSelector(state => state.timers.timers);
  const runningTimer = timers.find(timer => !timer.end);
  const unlinkedTimers = timers.filter(timer => !timer.task && timer.id !== runningTimer?.id).reverse();

  return (
    <Drawer
      anchor="right"
      isOpen={isOpen}
      handleToggle={handleToggle}
      options={unlinkedTimers}
      renderOption={option => (
        <ListItem style={{ display: 'block' }} key={option.id.toString()}>
          <Timer
            info={option}
            handleAction={handleOpen}
            addEvent={addEvent}
            actionTooltip={'Link with task'}
            onStop={() => {}}
          />
        </ListItem>
      )}
      header={
        <div>
          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            {runningTimer ? (
              <RunningTimer timer={runningTimer} handleOpen={handleOpen} addEvent={addEvent} />
            ) : (
              <AddTimer />
            )}
          </div>
          <DividerWithText text={'Pending task assignment'} />
        </div>
      }
    />
  );
};

export { ChronometersDrawer, IterationsDrawer };
