import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Calendar from '../components/Calendar';
import {
  ErrorToast,
  Header,
  ManualLoadDialog,
  QuickLoadDialog,
  SuccessToast,
  useBigCalendar,
  WarningToast
} from '../components/common';
import { ChronometersDrawer, IterationsDrawer } from '../components/common/Drawer';
import CreateTaskFromTimer from '../components/common/TimerLinking/CreateTask/CreateTaskFromTimer';
import LinkWithTaskDialog from '../components/common/TimerLinking/LinkWithTask/LinkWithTaskDialog';
import TimerDetails from '../components/common/TimerLinking/TimerDetails';
import { setTasks } from '../redux/actions';
import { fetchActiveTasks } from '../utils/tasks';
import { isLogged } from '../utils/utils';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  headerContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxWidth: '100vw',
  },
}));

const Home = props => {
  const classes = useStyles();
  const [iterationsOpen, setIterationsOpen] = useState(false);
  const [chronometersOpen, setChronometersOpen] = useState(false);
  const [newEffort, setNewEffort] = useState({});
  const [loadEffort, setLoadEffort] = useState(null);
  const [quickLoadDialogOpen, setQuickLoadDialogOpen] = useState(false);
  const [manualLoadDialogOpen, setManualLoadDialogOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { events, addEvent, editEvent, editEventTime, fetchEvents } = useBigCalendar({
    setWarningMessage, setSuccessMessage, setErrorMessage, setLoading
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if(!isLogged()) return props.history.push('/login');
    fetchActiveTasks(props).then(tasks => dispatch(setTasks(tasks)));
  }, [dispatch, props]);


  const [timerLinkingDialogOpen, setTimerLinkingDialogOpen] = useState(false);
  const [timerSelected, setTimerSelected] = useState(null);

  const [timerTaskSelectorOpen, setTimerTaskSelectorOpen] = useState(false);

  const [timerTaskCreatorOpen, setTimerTaskCreatorOpen] = useState(false);

  
  const toggleIterations = () => {
    if (!iterationsOpen) setChronometersOpen(false);
    setIterationsOpen(prev => !prev);
  };

  const toggleChronometers = () => {
    if (!chronometersOpen) setIterationsOpen(false);
    setChronometersOpen(prev => !prev);
  };

  const quickLoad = info => {
    setNewEffort(info);
    setLoadEffort(null);
    const durationHours = (info.end - info.start) / 3600000;
    if (durationHours <= 15) setQuickLoadDialogOpen(true);
    else if (durationHours < 24) setWarningMessage("Efforts can't exceed 15 hours");
  };

  const linkTaskWithTimer = timer => {
    setTimerSelected(timer);
    setTimerLinkingDialogOpen(true);
  };

  const handleCloseTimerLinking = () => {
    setTimerLinkingDialogOpen(false);
    setTimerSelected(null);
  };

  const manualLoad = (effort, isNew) => {
    if (effort) setLoadEffort({ ...effort, isNew });
    setManualLoadDialogOpen(true);
    cancelQuickLoad();
  };

  const cancelQuickLoad = () => {
    setQuickLoadDialogOpen(false);
    setNewEffort(null);
  };

  const cancelManualLoad = () => {
    setQuickLoadDialogOpen(false);
    setManualLoadDialogOpen(false);
    setLoadEffort(null);
    setNewEffort(null);
  };

  return (
    <div className={classes.root}>
      <IterationsDrawer isOpen={iterationsOpen} handleToggle={toggleIterations} manualLoad={manualLoad} toggleChronometers={toggleChronometers} />
      <div className={classes.headerContainer}>
        <Header
          toggleIterations={toggleIterations}
          toggleChronometers={toggleChronometers}
          iterationsOpen={iterationsOpen}
          chronometersOpen={chronometersOpen}
          history={props.history}
        />
        <div style={{ flex: 1 }}>
          <Calendar
            quickLoad={quickLoad}
            manualLoad={manualLoad}
            events={events}
            editEventTime={editEventTime}
            fetchEvents={fetchEvents}
            loading={loading}
          />
        </div>
      </div>
      <ChronometersDrawer
        isOpen={chronometersOpen}
        handleToggle={toggleChronometers}
        handleOpen={linkTaskWithTimer}
        addEvent={addEvent}
        handleStopIfNotLinked={timer => {
          setTimerSelected(timer);
          setTimerTaskSelectorOpen(true);
        }}
      />
      {loading && <CircularProgress
        style={{
          position: 'absolute',
          right: chronometersOpen? '25%' : '5%',
          bottom: '5%',
        }}
        size={30}
        thickness={5}
        color="primary"
      />}
      <QuickLoadDialog
        open={quickLoadDialogOpen}
        handleCloseDialog={cancelQuickLoad}
        info={newEffort}
        addEvent={addEvent}
        manualLoad={manualLoad}
        loading={loading}
      />
      <ManualLoadDialog
        open={manualLoadDialogOpen}
        handleClose={cancelManualLoad}
        info={loadEffort}
        addEvent={addEvent}
        editEvent={editEvent}
      />
      <TimerDetails
        open={timerLinkingDialogOpen}
        handleClose={handleCloseTimerLinking}
        selectedTimer={timerSelected}
        handleClick={() => setTimerTaskSelectorOpen(true)}
        reopen={() => setTimerLinkingDialogOpen(true)} // After canceling delete action, it should reopen the dialog
        addEvent={addEvent}
      />
      <LinkWithTaskDialog
        open={timerTaskSelectorOpen}
        handleClose={eventAdded => {
          setTimerTaskSelectorOpen(false);
          if (eventAdded) setTimerLinkingDialogOpen(false);
        }}
        selectedTimer={timerSelected}
        manualLoad={() => {
          setTimerTaskSelectorOpen(false);
          setTimerTaskCreatorOpen(true);
        }}
        addEvent={addEvent}
        loading={loading}
      />
      <CreateTaskFromTimer
        open={timerTaskCreatorOpen}
        handleClose={() => setTimerTaskCreatorOpen(false)}
        selectedTimer={timerSelected}
        addEvent={addEvent}
        history={props.history}
      />
      <WarningToast
        open={warningMessage !== ''}
        onClose={() => setWarningMessage('')}
        text={warningMessage}
        duration={4000}
      />
      <SuccessToast
        open={successMessage !== ''}
        onClose={() => setSuccessMessage('')}
        text={successMessage}
        duration={4000}
      />

      <ErrorToast
        open={errorMessage !== ''}
        onClose={() => setErrorMessage('')}
        text={errorMessage}
        duration={4000}
      />

    </div>
  );
};
export default Home;
