import { Button, DialogActions, DialogContentText, makeStyles } from '@material-ui/core';
import { default as React, useCallback, useEffect } from 'react';
import { getUserId } from '../../../utils/utils';
import AutocompleteChipSection from '../Autocomplete/AutocompleteChipSection';
import Dialog from '../Dialog';
import useFormValidation from '../hooks/useFormValidation';
import LoadEffortTime from '../LoadEffortTime';
import TaskList from '../TaskList/TaskList';
import { validate } from './validate';

const useStyles = makeStyles(() => ({
  dialogTaskText: { margin: '24px 0px 0px', color: 'black', fontSize: 16 },
}));

const INITIAL_STATE = {
  start: new Date(),
  end: new Date(),
  taskFilter: '',
  participants: [{
    id: getUserId(),
    fullName: "You",
  }],
};

const QuickLoadDialog = ({ open, handleCloseDialog, info, addEvent, manualLoad, loading, ...props }) => {
  const classes = useStyles();
  const { handleSubmit, handleChange, handleBlur, values, errors, resetError, resetValues } = useFormValidation(
    INITIAL_STATE,
    v => validate(v)
  );

  const handleClickTask = async ({ name, project, iteration, story, id }) => {
    const isFormValid = handleSubmit();

    if (!isFormValid) return;

    addEvent({
      start: values.start,
      end: values.end,
      name,
      project,
      iteration,
      story,
      id,
      participants: values.participants,
    })
    handleClose();
  };

  const handleClose = () => {
    resetValues(INITIAL_STATE);
    handleCloseDialog();
  }

  useEffect(() => {
    if (open) handleChange({ target: { name: 'taskFilter', value: '' } });
  }, [handleChange, open]);

  // Este código está repetido de ManualLoadDialog, abstraer luego del merge con la branch de Rankeo de tasks
  const customHandleAdd = useCallback(
    (field, value) =>
      ({ id, fullName }) =>
        handleChange({
          target: {
            name: field,
            value: [...value, { id, fullName: id === getUserId() ? 'You' : fullName }],
          },
        }),
    [handleChange]
  );

  return (
    <Dialog open={open} handleClose={handleClose} title={'Load new effort'}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '70vh',
          minHeight: '70vh',
        }}
      >
      <LoadEffortTime
        values={values}
        errors={errors}
        handleChange={handleChange}
        resetError={resetError}
        handleBlur={handleBlur}
        info={info}
      >
        <AutocompleteChipSection
            editMode
            path={'users'}
            apiField={'fullName'}
            idField={'id'}
            field="participants"
            value={values.participants}
            handleChange={handleChange}
            handleBlur={handleBlur}
            helpText={'Load effort for another responsibles by typing their name'}
            label="Add more people to this effort"
            customHandleAdd={customHandleAdd('participants', values.participants)}
          />
      </LoadEffortTime>
      <DialogContentText className={classes.dialogTaskText}>Select the task:</DialogContentText>

      <TaskList handleClickTask={handleClickTask} values={values} handleChange={handleChange} loading={loading} />
      </div>

      <DialogActions>
        <Button color="primary" onClick={() => manualLoad(info, true)}>
          {"Can't find the task"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuickLoadDialog;
