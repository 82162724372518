import { useCallback, useState } from 'react';
import { isEqual, mergeWith } from 'lodash';
import { getCookie, getDuration } from '../../../utils/utils';
import { get, handleResponse, patch, post } from '../../../utils/network';
import moment from 'moment';
import _ from 'lodash';

const mergeCustomizer = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
    return _.intersectionWith(objValue, srcValue, isEqual);
  }
}

const useBigCalendar = ({setWarningMessage, setErrorMessage, setSuccessMessage, setLoading }) => {
  const [events, setEvents] = useState([]);
  
  const addEvent = async (e) => {

    const {start, end, name, project, iteration, story, id, comments, state, responsibles, participants} = e;

    
    const newEvent = {
      start,
      end,
      title: name,
      resource: {
        project,
        iteration,
        story,
        task: { id, name },
        comments,
      }
    };

    if(state || responsibles) {
      await editTask(newEvent, {state, responsibles: responsibles.map(r => r.id)});
    }

    const previousEvents = Object.assign([], events);
    setEvents(prev => [...prev, newEvent]);

    let query;

    if(story.id) query = `clients/0/projects/${project.id}/iterations/${iteration.id}/stories/${story.id}/tasks/${id}/entries`;
    else query = `clients/0/projects/${project.id}/iterations/${iteration.id}/tasks/${id}/entries`;

    const duration = getDuration(start, end) * 60;
    const participants_id = participants.map(p => p.id);

    
    setLoading(true);
    try {
      const res = await post(query, {
        date: end,
        minutesSpent: duration,
        task_id: id,
        description: comments ?? '',
        participants: participants_id,
      });
      const {message: entryId} = await handleResponse(res);
      newEvent.id = entryId;
      setSuccessMessage('Effort added successfully');
      return entryId
    } catch(err) {
      setErrorMessage("There was an error adding effort");
      setEvents(previousEvents);
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  };

  const editEvent = async ({originalEvent, eventChanges}) => {

    const { id: entryId, resource: { project, iteration, story, task } } = originalEvent;
    const { start, end, resource } = eventChanges;

    if(resource?.state || resource?.responsibles){
      const { state, responsibles, ...rest } = resource;
      await editTask(originalEvent, {state: resource.state, responsibles: resource.responsibles?.map(r => r.id)});
      if(Object.keys(rest).length === 0) return;
    }

    let query;

    if(story.id) query = `clients/0/projects/${project.id}/iterations/${iteration.id}/stories/${story.id}/tasks/${task.id}/entries/${entryId}`;
    else query = `clients/0/projects/${project.id}/iterations/${iteration.id}/tasks/${task.id}/entries/${entryId}`;
    
    const body = {}
    
    if(start || end) {
      const newStart = start ?? originalEvent.start;
      const newEnd = end ?? originalEvent.end;
      const duration = getDuration(newStart, newEnd) * 60;
      body.minutesSpent = duration;
      if(!isEqual(originalEvent.end, end)) body.date = newEnd;
    }
    if(resource?.comments) body.description = resource.comments;
    
    const previousEvents = Object.assign([], events);
    setEvents(prev => prev.map(event => (event.id === entryId ? mergeWith(event, eventChanges, mergeCustomizer) : event)));
    if(Object.keys(body).length === 0) return Promise.resolve();
    
    setLoading(true);
    try {
      const response = await patch(query, body);
      const { statusCode } = await handleResponse(response);
      if(statusCode === 200) {
        setSuccessMessage('Effort edited successfully');
        return entryId;
      }
    } catch(err) {
      setErrorMessage("There was an error editing effort");
      setEvents(previousEvents);
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  }

  const isMovement = (event, start, end) => event.end - event.start === end - start;

  const editEventTime = ({ event, start, end }) => {
    const invalidTime = (end - start) / 3600000 > 8 && !isMovement(event, start, end); //if its an edition of more than 8h its most likely a mistake

    if (invalidTime) {
      setWarningMessage(
        "Remember that it's recommended to load efforts smaller than 8 hours. If you want to edit an effort with bigger duration, please click on the effort to access the manual load."
      );
      return;
    }

    if(end - start <= 900000) {
      setWarningMessage("You can't resize an event to less than 15 minutes");
      return;
    }

    const eventChanges = {};
    if(start !== event.start) eventChanges.start = start;
    if(end !== event.end) eventChanges.end = end;

    editEvent({
      originalEvent: event,
      eventChanges: eventChanges,
    });
      
  };

  const editTask = async (event, {state, responsibles}) => {
    const { id: entryId, resource: { project, iteration, story, task } } = event;

    const previousEvents = Object.assign([], events);
    setEvents(prev => prev.map(event => (event.id === entryId ? {...event, resource: {...event.resource, state}} : event)));

    let query;

    if(story.id) query = `clients/0/projects/${project.id}/iterations/${iteration.id}/stories/${story.id}/tasks/${task.id}`;
    else query = `clients/0/projects/${project.id}/iterations/${iteration.id}/tasks/${task.id}`;

    setLoading(true);
    try {
      const response = await patch(query, { state: state?.id ?? 0, responsibles});
      if(response.status === 204) {
        setSuccessMessage('Task state edited successfully');
        return entryId;
      }
    } catch(err) {
      setErrorMessage("There was an error editing task");
      setEvents(previousEvents);
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }

  }

  const fetchEvents = useCallback(async ({start, end}) => {

    const query = `users/${getCookie('userId')}/entries?start=${start}&end=${end}`;
    setLoading(true);
    try {
      const res = await get(query);
      const { message: entries } = await handleResponse(res);
      const events = entries.map(entry => ({
        start: moment(new Date(entry.date)).subtract(entry.minutesSpent, 'minutes').toDate(),
        end: new Date(entry.date), 
        title: entry.task.name,
        id: entry.id,
        resource: {
          project: entry.project,
          iteration: entry.iteration,
          story: entry.story,
          task: entry.task,
          comments: entry.description,
          responsibles: entry.responsibles || [],
        }
      }));
      setEvents(events);
    } catch(err) {
      setErrorMessage("There was an error fetching events");
    } finally {
      setLoading(false);
    }
  }, [setErrorMessage, setLoading]);

  return { events, addEvent, editEventTime, editEvent, fetchEvents };
};

export default useBigCalendar;
